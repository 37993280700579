<template>
  <div>
    <div class="three one" @click="saveReport">
      <van-icon name="label-o" size="16" />
      保存报告
    </div>
    <div class="three two" @click="report" v-if="cid != '928'">
      <van-icon name="service" size="16" />
      报告解读
    </div>
    <div class="three threes" @click="showZhaiwu" v-if="cid != '928'">
      <van-icon name="smile-comment-o" size="16" />
      债务咨询
    </div>

    <!-- 遮罩层 -->
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <div class="block">
          <p v-if="!imgurl" style="text-align: center">
            暂无在线的报告客服！！！
          </p>
          <p
            v-if="imgurl"
            style="
              text-align: center;
              font-size: 14px;
              margin-bottom: 12px;
              color: red;
            "
          >
            <strong>保存下面二维码，去微信添加客服<br />VIP1对1耐心解答</strong>
          </p>
          <img v-if="imgurl" style="border-radius: 5px" :src="imgurl" alt="" />
        </div>
        <div class="close">
          <van-icon name="close" size="30" color="#fff" @click="show = false" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import { Overlay, Toast } from 'vant';
import { server } from '../api/getUserInfo';
Vue.use(Overlay);
export default {
  props: ['imgurl', 'cid'],
  data() {
    return {
      show: false,
      url: '',
    };
  },
  mounted() {
    // setTimeout(()=>{
    //     this.report()
    // },5000)
  },
  methods: {
    // 保存报告
    saveReport() {
      this.$parent.download();
    },
    // 债务客服
    showZhaiwu() {
      this.$router.push({
        path: '/debtServe',
      });
      // server({type:5}).then(res=>{
      //     if(res.data.code==200){
      //         if(res.data.data!=null){
      //             // 有客服二维码返回 显示客服
      //             this.$router.push({
      //                 path:"/debtServe",
      //                 query:{
      //                     url:res.data.data
      //                 }
      //             })
      //         }else{
      //             // 不存在二维码 则直接跳转地址
      //             location.href = "https://www.whha888.com/credit/#/user?id=7febb8fd08326ecd2e586da4f6af48038fe18a7c"
      //         }

      //     }else{
      //         Toast(res.data.msg)
      //     }
      // }).catch(err=>{
      //     Toast(err);
      // })
    },
    cancleReport() {
      this.show = false;
    },
    // 报告解读
    report() {
      this.show = true;
      // 这里请求接口
      // server({ type: 3 })
      //   .then((res) => {
      //     if (res.data.code == 200) {
      //       this.url = res.data.data;
      //     } else {
      //       Toast(res.data.msg);
      //     }
      //   })
      //   .catch((err) => {
      //     Toast(err);
      //   });
    },
    // 债务规划
    debtPlan() {
      // location.href = "https://www.whha888.com/credit/#/user?id=7febb8fd08326ecd2e586da4f6af48038fe18a7c"
    },
  },
};
</script>

<style lang="less" scoped>
.three {
  width: 22vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 26px;
  margin-bottom: 30px;
  border-top-left-radius: 5.33333vw;
  border-bottom-left-radius: 5.33333vw;
  color: #fff;
  padding: 5px;
}
.one {
  background: linear-gradient(-90deg, #ff5427, #00bcd4);
  box-shadow: 0 0.8vw 2.66667vw 0 #ff7f1c;
}
.two {
  background: linear-gradient(-90deg, #449afb, #0060cd);
  box-shadow: 0 0.8vw 2.66667vw 0 #0060cd;
}
.threes {
  background: linear-gradient(-90deg, #ffca28, #ff9d19);
  box-shadow: 0 0.8vw 2.66667vw 0 #ff9d19;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.block {
  width: 66%;
  min-height: 300px;
  background-color: #fff;
  border-radius: 25px;
  padding: 5% 8%;
  img {
    width: 100%;
  }
  input {
    width: 100%;
    height: 75px;
    margin-bottom: 20px;
    background: #f0f0f0;
    color: #323233;
    border: none;
    border-radius: 12px;
    font-size: 28px;
    text-indent: 1em;
  }
  textarea {
    width: 100%;
    height: 200px;
    border-radius: 12px;
    font-size: 28px;
    text-indent: 1em;
    background: #f0f0f0;
    color: #323233;
    border: none;
  }
}
.close {
  margin-top: 20px;
}
button {
  width: 100%;
  height: 80px;
  color: white;
  background: linear-gradient(0deg, rgb(68, 154, 251) 0%, rgb(0, 96, 205) 100%);
  font-size: 28px;
  margin-top: 50px;
  border-radius: 50px;
  outline: none;
  border: none;
}
</style>
