<template>
  <div>
    <!-- 百融 -->
    <div v-if="product == 1" class="example" ref="imageTofile">
      <div class="header">
        <p class="p1">您的评分指数</p>
        <div class="div1">
          {{ rule_final_weight }}
        </div>
        <p class="p2">
          您的评分打败了全国{{ rule_final_weight | fraction }}%的用户
        </p>
      </div>
      <!-- 评分解读 -->
      <div class="score">
        <p class="basic_info"><span>评分解读</span></p>
        <div class="score_tab">
          <div
            class="score_tab_div"
            :style="rule_final_weight <= 20 ? 'color:#ff6a00' : 'color:#52a8ff'"
          >
            <p>0 ~ 20</p>
            <p>极差</p>
          </div>
          <div
            class="score_tab_div"
            :style="
              rule_final_weight > 20 && rule_final_weight <= 40
                ? 'color:#ff6a00'
                : 'color:#52a8ff'
            "
          >
            <p>20 ~ 40</p>
            <p>较差</p>
          </div>
          <div
            class="score_tab_div"
            :style="
              rule_final_weight > 40 && rule_final_weight <= 60
                ? 'color:#ff6a00'
                : 'color:#52a8ff'
            "
          >
            <p>40 ~ 60</p>
            <p>一般</p>
          </div>
          <div
            class="score_tab_div"
            :style="
              rule_final_weight > 60 && rule_final_weight <= 80
                ? 'color:#ff6a00'
                : 'color:#52a8ff'
            "
          >
            <p>60 ~ 80</p>
            <p>良好</p>
          </div>
          <div
            class="score_tab_div"
            :style="
              rule_final_weight > 80 && rule_final_weight <= 100
                ? 'color:#ff6a00'
                : 'color:#52a8ff'
            "
          >
            <p>80 ~ 100</p>
            <p>优秀</p>
          </div>
        </div>
        <p class="score_p">
          评分仅供参考，分数越高信用越好。本报告包含身份校验、借贷风险、失信等多个维度，是根据网络行为综合评估的，不包含个人隐私爬虫数据。
        </p>
      </div>

      <!--基本信息  -->
      <div class="score score1">
        <p class="basic_info"><span>基本信息</span></p>
        <div class="person" v-if="h5UserInfo">
          <p>
            <img src="../assets/image/example/xin-name.png" alt="" />{{
              h5UserInfo.username
            }}
            {{ h5UserInfo.age }}岁 {{ h5UserInfo.sex == 1 ? '男' : '女' }}
          </p>
          <p>
            <img src="../assets/image/example/xin-phone.png" alt="" />{{
              h5UserInfo.mobile
            }}
          </p>
          <p>身份证号：{{ h5UserInfo.idCard }}</p>
          <p>身份证归属地：{{ h5UserInfo.homeLocation }}</p>
          <p>报告生成时间：{{ h5UserInfo.createTime }}</p>
          <p>报告失效时间：{{ h5UserInfo.failureTime }}</p>
        </div>
      </div>
      <!-- 多头申请数据 -->
      <div class="title">
        <img src="../assets/image/example/xin1-tit1.png" alt="" />
        <span>多头申请数据</span>
        <img src="../assets/image/example/xin1-tit2.png" alt="" />
      </div>
      <!-- echarts -->
      <div class="echarts">
        <p class="title_p">多头数据走势</p>
        <div id="main" style="height: 250px"></div>
      </div>

      <p class="basic_info basic_info1"><span>多头借贷次数</span></p>

      <div class="bank">
        <p class="bank_p">银行类机构申请次数汇总(次)</p>
        <div class="bank_tab" v-if="bink">
          <div class="bank_tab_box" v-for="(item, index) in bink" :key="index">
            <p>{{ item.num || 0 }}</p>
            <p>{{ item.title }}</p>
          </div>
        </div>
        <p class="bank_p">非银行类机构申请次数汇总(次)</p>
        <div class="bank_tab">
          <div
            class="bank_tab_box"
            v-for="(item, index) in nobink"
            :key="index"
          >
            <p>{{ item.num || 0 }}</p>
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
      <!--多头借贷次数 tab切换 -->
      <div class="tabbox">
        <div class="tabbox-div">
          <p
            v-for="(item, index) in bink"
            :key="index"
            :class="active == index ? 'pactive' : ''"
            @click="switchs(index)"
          >
            {{ item.title }}
          </p>
        </div>
        <div class="banking" v-if="duotoulist">
          <p class="titles">银行类</p>
          <p>
            <span>传统银行</span><span>{{ duotoulist[0] }}</span>
          </p>
          <p>
            <span>网络零售银行</span><span>{{ duotoulist[1] }}</span>
          </p>
          <p class="titles">非银行类</p>
          <p>
            <span>p2p机构</span><span>{{ duotoulist[2] }}</span>
          </p>
          <p>
            <span>小贷机构</span><span>{{ duotoulist[3] }}</span>
          </p>
          <p>
            <span>现金类分期</span><span>{{ duotoulist[4] }}</span>
          </p>
          <p>
            <span>消费类分期</span><span>{{ duotoulist[5] }}</span>
          </p>
          <p>
            <span>代偿类分期</span><span>{{ duotoulist[6] }}</span>
          </p>
          <p>
            <span>其他</span><span>{{ duotoulist[7] }}</span>
          </p>
        </div>
      </div>

      <!-- 蓝色 -->
      <p class="basic_info basic_info1 bluRay2"><span>多头借贷机构数</span></p>

      <div class="bank banks">
        <p class="bank_p">银行类机构申请次数汇总(次)</p>
        <div class="bank_tab">
          <div
            class="bank_tab_box"
            v-for="(item, index) in bluebink"
            :key="index"
          >
            <p>{{ item.num || 0 }}</p>
            <p>{{ item.title }}</p>
          </div>
        </div>
        <p class="bank_p">非银行类机构申请次数汇总(次)</p>
        <div class="bank_tab">
          <div
            class="bank_tab_box"
            v-for="(item, index) in nobluebink"
            :key="index"
          >
            <p>{{ item.num || 0 }}</p>
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>

      <!-- tab切换 -->
      <div class="tabbox">
        <div class="tabbox-div tabbox-divs">
          <p
            v-for="(item, index) in bluebink"
            :key="index"
            :class="active1 == index ? 'pactive' : ''"
            @click="switchs1(index)"
          >
            {{ item.title }}
          </p>
        </div>
        <div class="banking">
          <p class="titles">银行类</p>
          <p>
            <span>传统银行</span><span>{{ duotoulists[0] }}</span>
          </p>
          <p>
            <span>网络零售银行</span><span>{{ duotoulists[1] }}</span>
          </p>
          <p class="titles">非银行类</p>
          <p>
            <span>p2p机构</span><span>{{ duotoulists[2] }}</span>
          </p>
          <p>
            <span>小贷机构</span><span>{{ duotoulists[3] }}</span>
          </p>
          <p>
            <span>现金类分期</span><span>{{ duotoulists[4] }}</span>
          </p>
          <p>
            <span>消费类分期</span><span>{{ duotoulists[5] }}</span>
          </p>
          <p>
            <span>代偿类分期</span><span>{{ duotoulists[6] }}</span>
          </p>
          <p>
            <span>其他</span><span>{{ duotoulists[7] }}</span>
          </p>
        </div>
      </div>

      <!-- 多头借贷异常时间段 -->
      <p class="basic_info basic_info1">
        <span>多头借贷异常时间段</span>
      </p>

      <div class="duotoutable">
        <p class="duotoutable-p">次数</p>
        <table border="1">
          <tr>
            <td></td>
            <td>近15天</td>
            <td>近1个月</td>
            <td>近3个月</td>
            <td>近6个月</td>
            <td>近12个月</td>
          </tr>
          <tr v-if="MuchHeadLoanErrorTimeSection[0]">
            <td>夜间-银行</td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[0][0] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[0][1] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[0][2] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[0][3] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[0][4] }}
            </td>
          </tr>
          <tr v-if="MuchHeadLoanErrorTimeSection[1]">
            <td>夜间-非银</td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[1][0] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[1][1] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[1][2] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[1][3] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[1][4] }}
            </td>
          </tr>
          <tr v-if="MuchHeadLoanErrorTimeSection[2]">
            <td>周末-银行</td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[2][0] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[2][1] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[2][2] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[2][3] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[2][4] }}
            </td>
          </tr>
          <tr v-if="MuchHeadLoanErrorTimeSection[3]">
            <td>周末-非银</td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[3][0] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[3][1] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[3][2] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[3][3] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[3][4] }}
            </td>
          </tr>
        </table>
      </div>

      <div class="duotoutable">
        <p class="duotoutable-p">机构数</p>
        <table border="1">
          <tr>
            <td></td>
            <td>近15天</td>
            <td>近1个月</td>
            <td>近3个月</td>
            <td>近6个月</td>
            <td>近12个月</td>
          </tr>
          <tr v-if="MuchHeadLoanErrorTimeSection[4]">
            <td>夜间-银行</td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[4][0] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[4][1] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[4][2] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[4][3] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[4][4] }}
            </td>
          </tr>
          <tr v-if="MuchHeadLoanErrorTimeSection[5]">
            <td>夜间-非银</td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[5][0] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[5][1] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[5][2] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[5][3] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[5][4] }}
            </td>
          </tr>
          <tr v-if="MuchHeadLoanErrorTimeSection[6]">
            <td>周末-银行</td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[6][0] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[6][1] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[6][2] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[6][3] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[6][4] }}
            </td>
          </tr>
          <tr v-if="MuchHeadLoanErrorTimeSection[7]">
            <td>周末-非银</td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[7][0] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[7][1] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[7][2] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[7][3] }}
            </td>
            <td style="color: #f25339">
              {{ MuchHeadLoanErrorTimeSection[7][4] }}
            </td>
          </tr>
        </table>
      </div>

      <!-- 多头借贷异常时间段 -->
      <p class="basic_info basic_info1">
        <span>失信情况总览</span>
      </p>
      <van-empty
        v-if="
          !expBad1Data.exp_bad1_casenum && !expExecut1Data.exp_execut1_casenum
        "
        description="法院、限制高消费、失信人名单，暂没有查到您的负面记录，请继续保持！"
      />

      <div class="overview" v-if="expBad1Data.exp_bad1_casenum">
        <p class="ovp"><span>失信被执行人</span></p>
        <div class="implement_info" v-if="h5UserInfo">
          <span>{{ h5UserInfo.username }}</span>
          <span>{{ h5UserInfo.idCard }}</span>
        </div>
        <p class="listp">
          <span class="listp_l">执行案号：</span>
          <span class="listp_r">{{ expBad1Data.exp_bad1_casenum }}</span>
        </p>
        <p class="listp">
          <span class="listp_l">立案时间：</span>
          <span class="listp_r">{{ expBad1Data.exp_bad1_time }}</span>
        </p>
        <p class="listp">
          <span class="listp_l">发布时间：</span>
          <span class="listp_r">{{ expBad1Data.exp_bad1_posttime }}</span>
        </p>
        <p class="listp">
          <span class="listp_l">执行法院：</span>
          <span class="listp_r">{{ expBad1Data.exp_bad1_court }}</span>
        </p>
        <p class="listp">
          <span class="listp_l">做出执行依据单位：</span>
          <span class="listp_r">{{ expBad1Data.exp_bad1_basecompany }}</span>
        </p>
        <p class="listp">
          <span class="listp_l">失信案件执行依据文号:</span>
          <span class="listp_r">{{ expBad1Data.exp_bad1_base }}</span>
        </p>
        <p class="listp">
          <span class="listp_l">被执行人的履行情况：</span>
          <span class="listp_r">{{ expBad1Data.exp_bad1_performance }}</span>
        </p>
        <p class="listp">
          <span class="listp_l">生效法律文书确定的义务：</span>
          <span class="listp_r">{{ expBad1Data.exp_bad1_obligation }}</span>
        </p>
        <p class="listp">
          <span class="listp_l">失信被执行人行为具体情形：</span>
          <span class="listp_r">{{
            expBad1Data.exp_bad1_concretesituation
          }}</span>
        </p>
      </div>
      <!-- --------------------------------------------------- -->
      <div class="overview" v-if="expExecut1Data.exp_execut1_casenum">
        <p class="ovp"><span>法院被执行人</span></p>
        <div class="implement_info" v-if="h5UserInfo">
          <span>{{ h5UserInfo.username }}</span>
          <span>{{ h5UserInfo.idCard }}</span>
        </div>

        <p class="listp">
          <span class="listp_l">执行案号：</span>
          <span class="listp_r">{{ expExecut1Data.exp_execut1_casenum }}</span>
        </p>
        <p class="listp">
          <span class="listp_l">执行标的：</span>
          <span class="listp_r">{{ expExecut1Data.exp_execut1_money }}</span>
        </p>
        <p class="listp">
          <span class="listp_l">立案时间：</span>
          <span class="listp_r">{{ expExecut1Data.exp_execut1_time }}</span>
        </p>
        <p class="listp">
          <span class="listp_l">执行法院：</span>
          <span class="listp_r">{{ expExecut1Data.exp_execut1_court }}</span>
        </p>
        <p class="listp">
          <span class="listp_l">案件状态：</span>
          <span class="listp_r">{{ expExecut1Data.exp_execut1_statute }}</span>
        </p>
      </div>
      <!-- ------------------------------------------ -->
      <p class="basic_info basic_info1 bluRay2"><span>高风险命中查询</span></p>

      <!-- 消费及汽车金融类高风险 -->
      <div class="highRisk">
        <p class="ovps"><span>消费及汽车金融类高风险</span></p>
        <div class="hrbox">
          <p class="hrboxp1"><span>网络小贷高风险</span><span>否</span></p>
          <p class="hrboxp2">风险类型：异常支付，仿冒风险</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>融资租赁高风险</span><span>否</span></p>
          <p class="hrboxp2">风险类型：汽车租赁违约</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>汽车金融高风险</span><span>否</span></p>
          <p class="hrboxp2">风险类型：车贷风险名单</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>车辆租赁违约名单</span><span>否</span></p>
          <p class="hrboxp2">风险类型：汽车租赁违约</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1">
            <span>风险类型：汽车故意违章</span><span>否</span>
          </p>
          <p class="hrboxp2">风险类型：汽车故意违章</p>
        </div>
      </div>

      <!-- 银行及法院类高风险 -->
      <div class="highRisk">
        <p class="ovps"><span>银行及法院类高风险</span></p>
        <div class="hrbox">
          <p class="hrboxp1">
            <span
              >银行机构高风险
              <span style="font-style: italic; margin-left: 20px"
                >否</span
              > </span
            ><span style="color: #dd524d; font-size: 12px">命中风险：0</span>
          </p>
          <p class="hrboxp2">风险类型：负债率高，频繁申贷，职业异常</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1">
            <span
              >银行机构高风险<span style="font-style: italic; margin-left: 20px"
                >否</span
              ></span
            ><span style="color: #dd524d; font-size: 12px">命中风险：0</span>
          </p>
          <p class="hrboxp2">风险类型：机构代办，异常审核</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1">
            <span
              >银行机构高风险<span style="font-style: italic; margin-left: 20px"
                >否</span
              ></span
            ><span style="color: #dd524d; font-size: 12px">命中风险：0</span>
          </p>
          <p class="hrboxp2">风险类型：当事人风险指数有增高倾向</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1">
            <span
              >银行机构高风险<span style="font-style: italic; margin-left: 20px"
                >否</span
              ></span
            ><span style="color: #dd524d; font-size: 12px">命中风险：0</span>
          </p>
          <p class="hrboxp2">风险类型：法院失信人</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1">
            <span
              >银行机构高风险<span style="font-style: italic; margin-left: 20px"
                >否</span
              ></span
            ><span style="color: #dd524d; font-size: 12px">命中风险：0</span>
          </p>
          <p class="hrboxp2">风险类型：法院执行人</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1">
            <span
              >银行机构高风险<span style="font-style: italic; margin-left: 20px"
                >否</span
              ></span
            ><span style="color: #dd524d; font-size: 12px">命中风险：0</span>
          </p>
          <p class="hrboxp2">风险类型：刑事犯罪</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1">
            <span
              >银行机构高风险<span style="font-style: italic; margin-left: 20px"
                >否</span
              ></span
            ><span style="color: #dd524d; font-size: 12px">命中风险：0</span>
          </p>
          <p class="hrboxp2">风险类型：欠税法人代表</p>
        </div>
      </div>

      <!-- 风险群体类高风险 -->
      <div class="highRisk">
        <p class="ovps"><span>风险群体类高风险</span></p>
        <div class="hrbox">
          <p class="hrboxp1"><span>风险群体名单</span><span>否</span></p>
          <p class="hrboxp2">风险类型：负债率高，频繁申贷，职业异常</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>欺诈风险名单</span><span>否</span></p>
          <p class="hrboxp2">风险类型：仿冒风险，资料异常</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>高负债名单</span><span>否</span></p>
          <p class="hrboxp2">风险类型：负债过高</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>行业黑名单</span><span>否</span></p>
          <p class="hrboxp2">风险类型：从事行业风险过高</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>羊毛党名单</span><span>否</span></p>
          <p class="hrboxp2">风险类型：恶意注册，刷单</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>恶意注册名单</span><span>否</span></p>
          <p class="hrboxp2">风险类型：异常支付，异常注册</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1">
            <span>身份证命中高风险区域</span><span>否</span>
          </p>
          <p class="hrboxp2">风险类型：身份证所属地区逾期风险较集中</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1">
            <span>归属地位于高风险集中地区</span><span>否</span>
          </p>
          <p class="hrboxp2">风险类型：归属地区逾期属于高风险集中地区</p>
        </div>
      </div>

      <!-- 信贷及逾期类高风险 -->
      <div class="highRisk">
        <p class="ovps"><span>信贷及逾期类高风险</span></p>
        <div class="hrbox">
          <p class="hrboxp1"><span>网络小贷高风险</span><span>否</span></p>
          <p class="hrboxp2">风险类型：异常支付，仿冒风险</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>线下小贷高风险</span><span>否</span></p>
          <p class="hrboxp2">风险类型：机构代办，资料异常</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>信贷逾期名单</span><span>否</span></p>
          <p class="hrboxp2">风险类型：逾期欠款</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>信贷逾期后还款名单</span><span>否</span></p>
          <p class="hrboxp2">风险类型：从事行业风险过高</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>网贷黑名单</span><span>否</span></p>
          <p class="hrboxp2">风险类型：信用异常</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>网贷灰名单</span><span>否</span></p>
          <p class="hrboxp2">风险类型：多头借款，有逾期倾向</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>多头借贷严重名单</span><span>否</span></p>
          <p class="hrboxp2">风险类型：频繁注册申请贷款</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1">
            <span>存在信用逾期历史记录</span><span>否</span>
          </p>
          <p class="hrboxp2">风险类型：逾期，逾期后还款</p>
        </div>
        <div class="hrbox">
          <p class="hrboxp1"><span>助学贷款欠费历史</span><span>否</span></p>
          <p class="hrboxp2">风险类型：助学贷款欠费逾期</p>
        </div>
      </div>

      <!-- 提高评分消除风险建议 -->
      <div class="vagueTitle">
        <span class="circularSmall"></span>
        <span class="circularIn"></span>
        <span class="circularLarge"></span>
        <span>提高评分消除风险建议</span>
        <span class="circularLarge"></span>
        <span class="circularIn"></span>
        <span class="circularSmall"></span>
      </div>

      <div class="vagueTitleBox1">
        <p>1.三个月不要注册申请超过20次贷款，控制申请频率</p>
        <p>
          2.如果有逾期或者被执行等不良记录，保持良好的信用1-2年，记录可以滚动覆盖过去
        </p>
        <p>3.保持良好的借贷习惯，切勿频繁申请，拒绝不明平台的审核邀请</p>
        <p>4.信用卡一年申请不超过6次，含同一机构，网贷3个月内申请不超过20次</p>
        <p>5.减少和网贷黑名单这类人群的联系，使自己朋友圈都是优良高素质群体</p>
      </div>

      <!-- 申贷小技巧 -->
      <div class="vagueTitle">
        <span class="circularSmall"></span>
        <span class="circularIn"></span>
        <span class="circularLarge"></span>
        <span>申贷小技巧</span>
        <span class="circularLarge"></span>
        <span class="circularIn"></span>
        <span class="circularSmall"></span>
      </div>
      <div class="vagueTitleBox1">
        <p>
          1.下载借款APP及申请的时候，平台需要获取定位、通讯录等权限，务必选择允许或者同意
        </p>
        <p>2.务必使用自己的实名制手机号，且号码使用超过半年</p>
        <p>
          3.如果手机号非本人实名，暂时只申请查征信上征信类的贷款，这类贷款不看重手机号是否实名制
        </p>
        <p>
          4.预留的联系人一定不能是银行或网贷有不良记录的，通话记录一定要和本人较多的
        </p>
        <p>5.删除手机中关于贷款、中介、赌博、网贷有逾期未还者</p>
        <p>6.把预留的三个联系人在通讯录改成真名</p>
        <p>7.把支付宝收货地址多的删掉，留下一个家庭和一个公司的</p>
        <p>
          8.把支付宝的网贷授权取消掉，申请网贷的同事，申请完一家，申请第二家的时候取消上一家的授权
        </p>
        <p>9.淘宝的收货地址尽量与支付宝保持一致</p>
        <p>
          10.放款机构普遍认为已婚有子女、高学历者，稳定性高，逾期率低，更容易通过
        </p>
        <p>11.人脸识别五官露出来，不戴眼镜帽子，光线适中</p>
        <p>12.签字笔需要正楷，自己不可潦草</p>
        <p>
          13.接回访电话时，按照所填资料如实回答，表现自己的还款意愿和还款能力，信用卡账单尽量网银导入
        </p>
        <p>14.下载及申请的时候，务必使用手机自带的流量，关闭WIFI新号</p>
      </div>
      <!-- 贷款最容易通过的行业 -->
      <div class="vagueTitle">
        <span class="circularSmall"></span>
        <span class="circularIn"></span>
        <span class="circularLarge"></span>
        <span>贷款最容易通过的行业</span>
        <span class="circularLarge"></span>
        <span class="circularIn"></span>
        <span class="circularSmall"></span>
      </div>
      <!-- 最后一个 -->
      <div class="footerbox">
        <p class="footerboxTitle"><span>容易通过的行业</span></p>
        <p class="fbcentent">1.大型国企事业单位</p>
        <p class="fbcentent">2.连锁型商场、超市</p>
        <p class="fbcentent">3.大型工厂</p>
        <p class="fbcentent">4.医药行业</p>
        <p class="fbcentent">5.科技公司</p>
        <p class="fbcentent">6.商贸公司</p>
        <p class="fbcentent">7.汽车用品</p>
        <p class="fbcentent">8.物流公司</p>
        <p class="fbfooter">
          偷偷告诉你：有实体经营，收入稳定的公司更容易通过贷款申请哦
        </p>
      </div>
      <div class="dwthree">
        <successthreeItem></successthreeItem>
      </div>

      <div v-if="zncpShow">
        <div class="vagueTitle" style="padding: 10px 0">
          <span class="circularSmall"></span>
          <span class="circularIn"></span>
          <span class="circularLarge"></span>
          <span>智能匹配产品</span>
          <span class="circularLarge"></span>
          <span class="circularIn"></span>
          <span class="circularSmall"></span>
        </div>
        <div class="reportBox" @click="smartBtn">
          <img src="@/assets/image/smart.jpg" alt="" />
          <!-- 由图片改为文字 -->
          <!-- <div  class="bannerContent">
                    <p class="">无门槛，<span style="font-size: 32px;">一元</span> 秒租机</p>
                    <p>价值最低可折8000元</p>
                    <p>无监管，放心租，放心借！全是好手机！</p>
                    <p>立即领取</p>
                </div> -->
        </div>
      </div>

      <div class="vagueTitle" style="padding: 10px 0">
        <span class="circularSmall"></span>
        <span class="circularIn"></span>
        <span class="circularLarge"></span>
        <span style="color: red; font-size: 22px; font-weight: 600"
          >报告解读</span
        >
        <span class="circularLarge"></span>
        <span class="circularIn"></span>
        <span class="circularSmall"></span>
      </div>
      <!-- 报告客服 -->
      <div class="reportBox">
        <p style="color: red; font-size: 16px">
          保存下面二维码，去微信添加客服<br />VIP1对1耐心解答
        </p>
        <img :src="reportImg" alt="" />
        <!-- <img src="https://manage.whha999.com/prod-api/path/apply/d53ff864d46e40d7ac9a4a4c409a726a.jpg" alt=""> -->
      </div>

      <!-- 报告截图预览 -->
      <van-popup v-model="show">
        <div class="previewImg">
          <img :src="imgUrl" alt="" @click="downloadImgUrl(imgUrl)" />
        </div>
      </van-popup>

      <!-- 智能客服 -->
      <van-overlay :show="smartShow" @click="smartShow = false">
        <div class="wrapper" @click.stop>
          <div class="block">
            <p v-if="!smartUrl" style="text-align: center">
              暂无在线的智能产品客服
            </p>
            <p
              v-if="smartUrl"
              style="text-align: center; font-size: 14px; margin-bottom: 12px"
            >
              <strong
                >保存下面二维码，去添加微信<br />客服专员 1对1 服务</strong
              >
            </p>
            <img
              v-if="smartUrl"
              style="border-radius: 5px"
              :src="smartUrl"
              alt=""
            />
          </div>
          <div class="close">
            <van-icon
              name="close"
              size="30"
              color="#fff"
              @click="smartShow = false"
            />
          </div>
        </div>
      </van-overlay>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { reference, server, urlInfo } from '@/api/getUserInfo';
import { Toast, Empty, Popup } from 'vant';
import successthreeItem from '../components/successthreeItem.vue';
import html2canvas from 'html2canvas';
Vue.use(Popup);
Vue.use(Empty);
export default {
  components: {
    successthreeItem,
  },
  name: 'example',
  data() {
    return {
      // 是否开启智能产品
      zncpShow: false,
      // 智能产品客服
      smartShow: false,
      // 银行类机构申请次数汇总(次)
      bink: [],
      // 非银行类机构申请次数汇总(次)(次)
      nobink: [],
      // 多头借贷次数 Tab
      dtdkcsTab: [],
      // 默认第一条数据 15天
      duotoulist: [],

      // 蓝色 银行类机构申请次数汇总(次)
      bluebink: [],
      // 非银行类机构申请次数汇总(次)
      nobluebink: [],
      // 多头借贷机构数 Tab
      dtdkjgTab: [],
      // 默认第一条数据 15天
      duotoulists: [],
      // 多头借贷异常时间段
      MuchHeadLoanErrorTimeSection: [],

      active: 0,
      active1: 0,
      h5UserInfo: null,
      expBad1Data: {},
      expExecut1Data: {},
      rule_final_weight: null,
      reportImg: '',
      // 报告截图预览
      show: false,
      imgUrl: '',
      smartUrl: '',
      jgsTop: undefined,
      jgsBtn: undefined,
      jgcsTop: undefined,
      jgcsBtn: undefined,
      product: 0,
      // 失信被执行人
      shixin: undefined,
    };
  },
  created() {},
  mounted() {
    this.bdInfo();
    //获取报告客服
    this.report();
  },
  filters: {
    //  0-20，打败0%
    // 20-40，打败30%
    // 40-60，打败50%
    // 60-80，打败70%
    // 80-100，打败85%
    fraction(val) {
      if (val < 20) {
        return 0;
      }
      if (val >= 20 && val < 40) {
        return 30;
      }
      if (val >= 40 && val < 60) {
        return 50;
      }
      if (val >= 60 && val < 80) {
        return 70;
      }
      if (val >= 80 && val <= 100) {
        return 85;
      }
    },
    dataNumber(val) {
      if (val == 0) {
        return '7天';
      }
      if (val == 1) {
        return '14天';
      }
      if (val == 2) {
        return '1个月';
      }
      if (val == 3) {
        return '3个月';
      }
      if (val == 4) {
        return '6个月';
      }
    },
  },
  methods: {
    smartBtn() {
      this.smartShow = true;
      server({ type: 4 })
        .then((res) => {
          if (res.data.code == 200) {
            this.smartUrl = res.data.data;
          } else {
            Toast(res.data.msg);
          }
        })
        .catch((err) => {
          Toast(err);
        });
      // location.href = "https://www.whha888.com/lessee/#/"
    },
    initCharts(data1, data2) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById('main'));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '',
        },
        // 提示框
        tooltip: {},
        // 图例
        legend: {
          data: ['申请次数', '申请机构数'],
        },
        // 表示x轴坐标
        xAxis: {
          data: ['15天', '1个月', '3个月', '6个月', '12个月'],
        },
        // 表示y轴坐标
        yAxis: {
          show: false,
          // minInterval: 1, //分割刻度
        },
        //
        series: [
          {
            name: '申请次数',
            type: 'bar',
            data: data1,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'top',
                  textStyle: {
                    color: '#ec735e',
                  },
                },
              },
            },
            color: '#ec735e',
          },
          {
            name: '申请机构数',
            type: 'bar',
            data: data2,
            type: 'bar',
            showBackground: true,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'top',
                  textStyle: {
                    color: '#6182d3',
                  },
                },
              },
            },

            color: '#6182d3',
          },
        ],
      };
      myChart.setOption(option);
    },
    switchs(index) {
      this.active = index;
      this.duotoulist = this.dtdkcsTab[index];
    },
    switchs1(index) {
      this.active1 = index;

      this.duotoulists = this.dtdkjgTab[index];
    },
    download() {
      // 生成报告过渡作用
      let toast2 = Toast.loading({
        duration: 0,
        message: '生成报告中...',
        forbidClick: true,
      });
      html2canvas(this.$refs.imageTofile, {
        // 设置清晰度
        scale: 1.8,
      }).then((canvas) => {
        // 清除toast2的弹窗
        toast2.clear();
        // 生成base64图片编码
        let dataURL = canvas.toDataURL('image/png');
        // 打开遮罩层预览图片
        this.show = true;
        this.imgUrl = dataURL;
      });
    },
    downloadImage(url) {
      // 如果是在网页中可以直接创建一个 a 标签直接下载
      let a = document.createElement('a');
      a.href = url;
      a.download = '报告';
      a.click();
    },
    // 下载报告
    downloadImgUrl(img) {
      var u = navigator.userAgent,
        app = navigator.appVersion;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      if (isAndroid) {
        this.downloadImage(img);
      } else {
        Toast('长按保存图片');
      }
    },
    // 背调数据
    bdInfo() {
      let toast1 = Toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      });
      reference({ orderNo: this.$route.query.orderNo })
        .then((result) => {
          if (result.data.code == 200) {
            this.product = result.data.data.product;
            // 2 是天创
            if (result.data.data.product == 2) {
              toast1.clear();
              let res = result.data;
              // 个人信息
              this.h5UserInfo = res.data.h5UserInfo;
              // 分数
              let sum = 0;
              for (let item in res.data.tcSource) {
                if (res.data.tcSource[item] == -1) {
                  sum += 951;
                } else {
                  sum += res.data.tcSource[item] * 1;
                }
              }
              this.rule_final_weight = parseInt(sum / 30);

              // 多头借贷机构数 机构机构机构 muchHeadApplyVerifyProductOrganizationMobileNumList
              this.jgsTop =
                res.data.muchHeadApplyVerifyProductOrganizationMobileNumList;
              this.jgsBtn =
                res.data.muchHeadApplyVerifyProductOrganizationIdCardNumList;

              // 多头借贷次数 次数次数次数次数
              this.jgcsTop = res.data.muchHeadApplyVerifyProductIdCardNumList;
              this.jgcsBtn =
                res.data.muchHeadApplyNotVerifyProductIdCardNumList;
              // 失信被执行人
              if (res.data.detail.shixin != null) {
                this.shixin = res.data.detail.shixin[0];
              }

              // echatrs
              this.$nextTick(() => {
                this.initCharts(this.jgcsTop, this.jgcsBtn);
              });
            } else {
              this.rule_final_weight = result.data.data.ruleFinalWeight;
              this.h5UserInfo = result.data.data.h5UserInfo;
              // 失信人
              this.expBad1Data = result.data.data.expBad1Data;
              // 被执行人
              this.expExecut1Data = result.data.data.expExecut1Data;
              // 多头借贷次数 ---银行类机构申请次数汇总(次)---------------------------
              let muchHeadLoanNum = result.data.data.muchHeadLoanNum;
              this.bink = [
                {
                  title: '近15天',
                  num: muchHeadLoanNum.als_d15_id_bank_allnum,
                },
                {
                  title: '近1个月',
                  num: muchHeadLoanNum.als_m1_id_bank_allnum,
                },
                {
                  title: '近3个月',
                  num: muchHeadLoanNum.als_m3_id_bank_allnum,
                },
                {
                  title: '近6个月',
                  num: muchHeadLoanNum.als_m6_id_bank_allnum,
                },
                {
                  title: '近12个月',
                  num: muchHeadLoanNum.als_m12_id_bank_allnum,
                },
              ];
              // 多头借贷次数 ---非银行类机构申请次数汇总(次)(次)
              this.nobink = [
                {
                  title: '近15天',
                  num: muchHeadLoanNum.als_d15_id_nbank_allnum,
                },
                {
                  title: '近1个月',
                  num: muchHeadLoanNum.als_m1_id_nbank_allnum,
                },
                {
                  title: '近3个月',
                  num: muchHeadLoanNum.als_m3_id_nbank_allnum,
                },
                {
                  title: '近6个月',
                  num: muchHeadLoanNum.als_m6_id_nbank_allnum,
                },
                {
                  title: '近12个月',
                  num: muchHeadLoanNum.als_m12_id_nbank_allnum,
                },
              ];
              // 多头借贷次数 ---tab切换
              this.dtdkcsTab = [
                [
                  muchHeadLoanNum.als_d15_id_bank_tra_allnum,
                  muchHeadLoanNum.als_d15_id_bank_ret_allnum,
                  muchHeadLoanNum.als_d15_id_nbank_p2p_allnum,
                  muchHeadLoanNum.als_d15_id_nbank_mc_allnum,
                  muchHeadLoanNum.als_d15_id_nbank_ca_allnum,
                  muchHeadLoanNum.als_d15_id_nbank_cf_allnum,
                  muchHeadLoanNum.als_d15_id_nbank_com_allnum,
                  muchHeadLoanNum.als_d15_id_oth_allnum,
                ],
                [
                  muchHeadLoanNum.als_m1_id_bank_tra_allnum,
                  muchHeadLoanNum.als_m1_id_bank_ret_allnum,
                  muchHeadLoanNum.als_m1_id_nbank_p2p_allnum,
                  muchHeadLoanNum.als_m1_id_nbank_mc_allnum,
                  muchHeadLoanNum.als_m1_id_nbank_ca_allnum,
                  muchHeadLoanNum.als_m1_id_nbank_cf_allnum,
                  muchHeadLoanNum.als_m1_id_nbank_com_allnum,
                  muchHeadLoanNum.als_m1_id_oth_allnum,
                ],
                [
                  muchHeadLoanNum.als_m3_id_bank_tra_allnum,
                  muchHeadLoanNum.als_m3_id_bank_ret_allnum,
                  muchHeadLoanNum.als_m3_id_nbank_p2p_allnum,
                  muchHeadLoanNum.als_m3_id_nbank_mc_allnum,
                  muchHeadLoanNum.als_m3_id_nbank_ca_allnum,
                  muchHeadLoanNum.als_m3_id_nbank_cf_allnum,
                  muchHeadLoanNum.als_m3_id_nbank_com_allnum,
                  muchHeadLoanNum.als_m3_id_oth_allnum,
                ],
                [
                  muchHeadLoanNum.als_m6_id_bank_tra_allnum,
                  muchHeadLoanNum.als_m6_id_bank_ret_allnum,
                  muchHeadLoanNum.als_m6_id_nbank_p2p_allnum,
                  muchHeadLoanNum.als_m6_id_nbank_mc_allnum,
                  muchHeadLoanNum.als_m6_id_nbank_ca_allnum,
                  muchHeadLoanNum.als_m6_id_nbank_cf_allnum,
                  muchHeadLoanNum.als_m6_id_nbank_com_allnum,
                  muchHeadLoanNum.als_m6_id_oth_allnum,
                ],
                [
                  muchHeadLoanNum.als_m12_id_bank_tra_allnum,
                  muchHeadLoanNum.als_m12_id_bank_ret_allnum,
                  muchHeadLoanNum.als_m12_id_nbank_p2p_allnum,
                  muchHeadLoanNum.als_m12_id_nbank_mc_allnum,
                  muchHeadLoanNum.als_m12_id_nbank_ca_allnum,
                  muchHeadLoanNum.als_m12_id_nbank_cf_allnum,
                  muchHeadLoanNum.als_m12_id_nbank_com_allnum,
                  muchHeadLoanNum.als_m12_id_oth_allnum,
                ],
              ];
              // 第一条数据 15天
              this.duotoulist = [
                muchHeadLoanNum.als_d15_id_bank_tra_allnum,
                muchHeadLoanNum.als_d15_id_bank_ret_allnum,
                muchHeadLoanNum.als_d15_id_nbank_p2p_allnum,
                muchHeadLoanNum.als_d15_id_nbank_mc_allnum,
                muchHeadLoanNum.als_d15_id_nbank_ca_allnum,
                muchHeadLoanNum.als_d15_id_nbank_cf_allnum,
                muchHeadLoanNum.als_d15_id_nbank_com_allnum,
                muchHeadLoanNum.als_d15_id_oth_allnum,
              ];

              // 多头借贷机构数------------------------------------------
              let MuchHeadLoanOrganizationNum =
                result.data.data.muchHeadLoanOrganizationNum;
              this.bluebink = [
                {
                  title: '近15天',
                  num: MuchHeadLoanOrganizationNum.als_d15_id_bank_orgnum,
                },
                {
                  title: '近1个月',
                  num: MuchHeadLoanOrganizationNum.als_m1_id_bank_orgnum,
                },
                {
                  title: '近3个月',
                  num: MuchHeadLoanOrganizationNum.als_m3_id_bank_orgnum,
                },
                {
                  title: '近6个月',
                  num: MuchHeadLoanOrganizationNum.als_m6_id_bank_orgnum,
                },
                {
                  title: '近12个月',
                  num: MuchHeadLoanOrganizationNum.als_m12_id_bank_orgnum,
                },
              ];
              this.nobluebink = [
                {
                  title: '近15天',
                  num: MuchHeadLoanOrganizationNum.als_d15_id_nbank_orgnum,
                },
                {
                  title: '近1个月',
                  num: MuchHeadLoanOrganizationNum.als_m1_id_nbank_orgnum,
                },
                {
                  title: '近3个月',
                  num: MuchHeadLoanOrganizationNum.als_m3_id_nbank_orgnum,
                },
                {
                  title: '近6个月',
                  num: MuchHeadLoanOrganizationNum.als_m6_id_nbank_orgnum,
                },
                {
                  title: '近12个月',
                  num: MuchHeadLoanOrganizationNum.als_m12_id_nbank_orgnum,
                },
              ];
              this.dtdkjgTab = [
                [
                  MuchHeadLoanOrganizationNum.als_d15_id_bank_tra_orgnum,
                  MuchHeadLoanOrganizationNum.als_d15_id_bank_ret_orgnum,
                  MuchHeadLoanOrganizationNum.als_d15_id_nbank_p2p_orgnum,
                  MuchHeadLoanOrganizationNum.als_d15_id_nbank_mc_orgnum,
                  MuchHeadLoanOrganizationNum.als_d15_id_nbank_ca_orgnum,
                  MuchHeadLoanOrganizationNum.als_d15_id_nbank_cf_orgnum,
                  MuchHeadLoanOrganizationNum.als_d15_id_nbank_com_orgnum,
                  MuchHeadLoanOrganizationNum.als_d15_id_oth_orgnum,
                ],
                [
                  MuchHeadLoanOrganizationNum.als_m1_id_bank_tra_orgnum,
                  MuchHeadLoanOrganizationNum.als_m1_id_bank_ret_orgnum,
                  MuchHeadLoanOrganizationNum.als_m1_id_nbank_p2p_orgnum,
                  MuchHeadLoanOrganizationNum.als_m1_id_nbank_mc_orgnum,
                  MuchHeadLoanOrganizationNum.als_m1_id_nbank_ca_orgnum,
                  MuchHeadLoanOrganizationNum.als_m1_id_nbank_cf_orgnum,
                  MuchHeadLoanOrganizationNum.als_m1_id_nbank_com_orgnum,
                  MuchHeadLoanOrganizationNum.als_m1_id_oth_orgnum,
                ],
                [
                  MuchHeadLoanOrganizationNum.als_m3_id_bank_tra_orgnum,
                  MuchHeadLoanOrganizationNum.als_m3_id_bank_ret_orgnum,
                  MuchHeadLoanOrganizationNum.als_m3_id_nbank_p2p_orgnum,
                  MuchHeadLoanOrganizationNum.als_m3_id_nbank_mc_orgnum,
                  MuchHeadLoanOrganizationNum.als_m3_id_nbank_ca_orgnum,
                  MuchHeadLoanOrganizationNum.als_m3_id_nbank_cf_orgnum,
                  MuchHeadLoanOrganizationNum.als_m3_id_nbank_com_orgnum,
                  MuchHeadLoanOrganizationNum.als_m3_id_oth_orgnum,
                ],
                [
                  MuchHeadLoanOrganizationNum.als_m6_id_bank_tra_orgnum,
                  MuchHeadLoanOrganizationNum.als_m6_id_bank_ret_orgnum,
                  MuchHeadLoanOrganizationNum.als_m6_id_nbank_p2p_orgnum,
                  MuchHeadLoanOrganizationNum.als_m6_id_nbank_mc_orgnum,
                  MuchHeadLoanOrganizationNum.als_m6_id_nbank_ca_orgnum,
                  MuchHeadLoanOrganizationNum.als_m6_id_nbank_cf_orgnum,
                  MuchHeadLoanOrganizationNum.als_m6_id_nbank_com_orgnum,
                  MuchHeadLoanOrganizationNum.als_m6_id_oth_orgnum,
                ],
                [
                  MuchHeadLoanOrganizationNum.als_m12_id_bank_tra_orgnum,
                  MuchHeadLoanOrganizationNum.als_m12_id_bank_ret_orgnum,
                  MuchHeadLoanOrganizationNum.als_m12_id_nbank_p2p_orgnum,
                  MuchHeadLoanOrganizationNum.als_m12_id_nbank_mc_orgnum,
                  MuchHeadLoanOrganizationNum.als_m12_id_nbank_ca_orgnum,
                  MuchHeadLoanOrganizationNum.als_m12_id_nbank_cf_orgnum,
                  MuchHeadLoanOrganizationNum.als_m12_id_nbank_com_orgnum,
                  MuchHeadLoanOrganizationNum.als_m12_id_oth_orgnum,
                ],
              ];
              this.duotoulists = [
                MuchHeadLoanOrganizationNum.als_d15_id_bank_tra_orgnum,
                MuchHeadLoanOrganizationNum.als_d15_id_bank_ret_orgnum,
                MuchHeadLoanOrganizationNum.als_d15_id_nbank_p2p_orgnum,
                MuchHeadLoanOrganizationNum.als_d15_id_nbank_mc_orgnum,
                MuchHeadLoanOrganizationNum.als_d15_id_nbank_ca_orgnum,
                MuchHeadLoanOrganizationNum.als_d15_id_nbank_cf_orgnum,
                MuchHeadLoanOrganizationNum.als_d15_id_nbank_com_orgnum,
                MuchHeadLoanOrganizationNum.als_d15_id_oth_orgnum,
              ];

              let echartsOrange = [
                muchHeadLoanNum.als_d15_id_bank_allnum +
                  muchHeadLoanNum.als_d15_id_nbank_allnum,
                muchHeadLoanNum.als_m1_id_bank_allnum +
                  muchHeadLoanNum.als_m1_id_nbank_allnum,
                muchHeadLoanNum.als_m3_id_bank_allnum +
                  muchHeadLoanNum.als_m3_id_nbank_allnum,
                muchHeadLoanNum.als_m6_id_bank_allnum +
                  muchHeadLoanNum.als_m6_id_nbank_allnum,
                muchHeadLoanNum.als_m12_id_bank_allnum +
                  muchHeadLoanNum.als_m12_id_nbank_allnum,
              ];
              let echartsBlue = [
                MuchHeadLoanOrganizationNum.als_d15_id_bank_orgnum +
                  MuchHeadLoanOrganizationNum.als_d15_id_nbank_orgnum,
                MuchHeadLoanOrganizationNum.als_m1_id_bank_orgnum * 1 +
                  MuchHeadLoanOrganizationNum.als_m1_id_nbank_orgnum * 1,
                MuchHeadLoanOrganizationNum.als_m3_id_bank_orgnum +
                  MuchHeadLoanOrganizationNum.als_m3_id_nbank_orgnum,
                MuchHeadLoanOrganizationNum.als_m6_id_bank_orgnum +
                  MuchHeadLoanOrganizationNum.als_m6_id_nbank_orgnum,
                MuchHeadLoanOrganizationNum.als_m12_id_bank_orgnum +
                  MuchHeadLoanOrganizationNum.als_m12_id_nbank_orgnum,
              ];
              // echatrs图标
              this.$nextTick(() => {
                this.initCharts(echartsOrange, echartsBlue);
              });
              // 多头借贷异常时间段
              // 次数
              let muHLTiS = result.data.data.muchHeadLoanErrorTimeSection;
              // 夜间银行
              let bank_night = [
                muHLTiS.als_d15_id_bank_night_allnum,
                muHLTiS.als_m1_id_bank_night_allnum,
                muHLTiS.als_m3_id_bank_night_allnum,
                muHLTiS.als_m6_id_bank_night_allnum,
                muHLTiS.als_m12_id_bank_night_allnum,
              ];
              // 夜间非银
              let nbank_night = [
                muHLTiS.als_d15_id_nbank_night_allnum,
                muHLTiS.als_m1_id_nbank_night_allnum,
                muHLTiS.als_m3_id_nbank_night_allnum,
                muHLTiS.als_m6_id_nbank_night_allnum,
                muHLTiS.als_m12_id_nbank_night_allnum,
              ];

              // 周末银行
              let bank_week = [
                muHLTiS.als_d15_id_bank_week_allnum,
                muHLTiS.als_m1_id_bank_week_allnum,
                muHLTiS.als_m3_id_bank_week_allnum,
                muHLTiS.als_m6_id_bank_week_allnum,
                muHLTiS.als_m12_id_bank_week_allnum,
              ];

              // 周末非银
              let nbank_week = [
                muHLTiS.als_d15_id_nbank_week_allnum,
                muHLTiS.als_m1_id_nbank_week_allnum,
                muHLTiS.als_m3_id_nbank_week_allnum,
                muHLTiS.als_m6_id_nbank_week_allnum,
                muHLTiS.als_m12_id_nbank_week_allnum,
              ];

              // 机构数
              // 夜间银行
              let bank_night_orgnum = [
                muHLTiS.als_d15_id_bank_night_orgnum,
                muHLTiS.als_m1_id_bank_night_orgnum,
                muHLTiS.als_m3_id_bank_night_orgnum,
                muHLTiS.als_m6_id_bank_night_orgnum,
                muHLTiS.als_m12_id_bank_night_orgnum,
              ];

              // 夜间-非银
              let nbank_night_orgnum = [
                muHLTiS.als_d15_id_nbank_night_orgnum,
                muHLTiS.als_m1_id_nbank_night_orgnum,
                muHLTiS.als_m3_id_nbank_night_orgnum,
                muHLTiS.als_m6_id_nbank_night_orgnum,
                muHLTiS.als_m12_id_nbank_night_orgnum,
              ];

              // 周末银行
              let bank_week_orgnum = [
                muHLTiS.als_d15_id_bank_week_orgnum,
                muHLTiS.als_m1_id_bank_week_orgnum,
                muHLTiS.als_m3_id_bank_week_orgnum,
                muHLTiS.als_m6_id_bank_week_orgnum,
                muHLTiS.als_m12_id_bank_week_orgnum,
              ];

              // 周末非银
              let nbank_week_orgnum = [
                muHLTiS.als_d15_id_nbank_week_orgnum,
                muHLTiS.als_m1_id_nbank_week_orgnum,
                muHLTiS.als_m3_id_nbank_week_orgnum,
                muHLTiS.als_m6_id_nbank_week_orgnum,
                muHLTiS.als_m12_id_nbank_week_orgnum,
              ];
              // 多头借贷异常时间段 两个table表格
              this.MuchHeadLoanErrorTimeSection = [
                bank_night,
                nbank_night,
                bank_week,
                nbank_week,
                bank_night_orgnum,
                nbank_night_orgnum,
                bank_week_orgnum,
                nbank_week_orgnum,
              ];
              toast1.clear();
            }
          } else if (result.data.code == 70002) {
            let cid = window.sessionStorage.getItem('cid');
            Toast('操作异常');
            setTimeout(() => {
              this.$router.push({
                path: `/${cid}`,
              });
            }, 2000);
          } else {
            Toast(result.data.msg);

            setTimeout(() => {
              let cid = this.$route.query.cid;
              let templateId = this.$route.query.templateId;
              if (!cid) {
                return;
              }
              if (cid && templateId) {
                console.log(this.$route.query.cid, templateId);
                // 等于1 就是默认模板
                if (templateId == 1) {
                  this.$router.push({
                    path: `/${cid}`,
                  });
                } else {
                  this.$router.push({
                    path: `/home${templateId}`,
                    query: {
                      cid,
                      templateId,
                    },
                  });
                }
              }
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          toast1.clear();
        });
    },
    report() {
      server({ type: 3 })
        .then((res) => {
          if (res.data.code == 200) {
            this.reportImg = res.data.data;
          } else {
            Toast(res.data.msg);
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
  },
  beforeDestroy() {
    // let cid = this.$route.query.cid
    // let templateId = this.$route.query.templateId
    // if(!cid){
    //     return
    // }
    // if(cid&&templateId){
    //     // 等于1 就是默认模板
    //     if(templateId==1){
    //         this.$router.push({
    //             path:`/${cid}`,
    //         })
    //     }else{
    //         this.$router.push({
    //             path:`/home${templateId}`,
    //             query:{
    //                 cid,
    //                 templateId
    //             }
    //         })
    //     }
    // }
    // return
  },
};
</script>

<style lang="less" scoped>
.example {
  width: 100%;
  height: auto;
  background: #eee;
  padding-bottom: 50px;
}
.header {
  width: 100%;
  height: 550px;
  background-image: linear-gradient(
      141deg,
      #9455b9 6%,
      #7266c3 52%,
      #5076cd 100%
    ),
    linear-gradient(#283094, #283094);
  background-blend-mode: normal, normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  .p1 {
    font-size: 32px;
    color: #fff;
    line-height: 130px;
  }
  .div1 {
    width: 200px;
    height: 200px;
    text-align: center;
    line-height: 200px;
    color: #34a0ff;
    font-size: 66px;
    background-image: url('../assets/image/example/score_bg.png');
    background-position: 100% 100%;
    background-size: contain;
  }
  .p2 {
    font-size: 24px;
    color: #fff;
    line-height: 130px;
  }
}
// 评分解读
.score {
  width: 92%;
  height: 442px;
  background: #fff;
  margin: 0 auto;
  border-radius: 15px;
  margin-top: -100px;
  margin-bottom: 130px;
  padding-bottom: 20px;
  .basic_info {
    display: inline-block;
    height: 54px;
    background: #ff7643;
    font-size: 30px;
    color: #ffffff;
    line-height: 54px;
    padding-left: 30px;
    margin-top: 45px;
    position: relative;
  }
  span:after {
    content: '';
    width: 0;
    height: 0;
    border-width: 27px;
    border-style: solid;
    border-color: #ff7643 transparent #ff7643 #ff7643;
    position: absolute;
    top: 0;
  }
  .score_tab {
    width: 100%;
    height: 120px;
    // background: pink;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .score_tab_div {
      display: flex;
      flex-direction: column;
      text-align: center;
      line-height: 60px;
      font-size: 30px;
    }
  }
  .score_p {
    font-size: 25px;
    width: 92%;
    margin: 50px auto;
  }
  .person {
    width: 88%;
    margin: 40px auto 40px auto;
    p {
      margin-bottom: 20px;
      font-size: 26px;
      display: flex;
      align-items: center;
      img {
        width: 36px;
        margin-right: 10px;
      }
    }
  }
}
.score1 {
  height: 520px;
  margin-top: -80px;
}

// 多头数据
.title {
  width: 100%;
  height: 66px;
  font-size: 38px;
  color: #0d92ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -80px;
  margin-bottom: 50px;
  img {
    width: 80px;
  }
}
// echarts
.echarts {
  width: 92%;
  height: 530px;
  background: #fff;
  border-radius: 15px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  .title_p {
    width: 100%;
    height: 66px;
    font-size: 26px;
    line-height: 66px;
  }
  .main {
    width: 100%;
    height: 400px;
  }
}
.basic_info {
  display: inline-block;
  height: 54px;
  background: #ff7643;
  font-size: 30px;
  color: #ffffff;
  line-height: 54px;
  padding-left: 30px;
  position: relative;
}
.basic_info span:after {
  content: '';
  width: 0;
  height: 0;
  border-width: 27px;
  border-style: solid;
  border-color: #ff7643 transparent #ff7643 #ff7643;
  position: absolute;
  /* right: -0.54rem; */
  top: 0;
}
.basic_info1 {
  margin: 50px 0;
}
.bank {
  width: 92%;
  height: 400px;
  margin: 0 auto;
  border-radius: 15px;
  background-image: linear-gradient(-85deg, #f32121 0%, #df823e 100%),
    linear-gradient(#7c95d5, #7c95d5);
  background-blend-mode: normal, normal;
  box-shadow: 0rem 0.05rem 0.13rem 0rem rgb(131 181 184 / 59%);
  opacity: 0.75;
  padding: 20px;
  box-sizing: border-box;
}
.bank_p {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 30px;
  text-align: center;
  color: #fff;
  margin-top: 10px;
}
.bank_tab {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  line-height: 50px;
  text-align: center;
  margin-top: 10px;
  .bank_tab_box {
    p:nth-child(1) {
      font-size: 36px;
      color: #ffe241;
    }
    p:nth-child(2) {
      font-size: 26px;
      color: #fff;
    }
  }
}
.tabbox {
  width: 92%;
  height: 850px;
  background: #fff;
  border-radius: 15px;
  margin: 40px auto;
  &-div {
    width: 92%;
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 20px 0;

    p {
      text-align: center;
      width: 120px;
      height: 40px;
      border: 1px solid #ff7545;
      color: #ff7545;
    }
    p:nth-child(1) {
      border-right: none;
    }
    p:nth-child(2) {
      border-right: none;
    }
    p:nth-child(3) {
      border-right: none;
    }
    p:nth-child(4) {
      border-right: none;
    }
    .pactive {
      color: #fff;
      background: #ff7545;
    }
  }
  &-divs {
    width: 92%;
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 20px 0;

    p {
      text-align: center;
      width: 120px;
      height: 40px;
      border: 1px solid #305bc9;
      color: #305bc9;
    }
    .pactive {
      color: #fff;
      background: #305bc9;
    }
  }
}
.banking {
  padding: 20px 40px;
  p {
    display: flex;
    justify-content: space-between;
    font-size: 26px;
    margin-bottom: 30px;
  }
  .titles {
    font-size: 32px;
    font-weight: 600;
  }
}
.bluRay2 {
  display: inline-block;
  height: 54px;
  background: #7c95d5;
  font-size: 30px;
  color: #ffffff;
  line-height: 54px;
  padding-left: 30px;
  margin-top: 45px;
  position: relative;
  span {
    display: inline-block;
    height: 54px;
    background-color: #7c95d5;
    line-height: 54px;
    padding: 0 12px 0 4px;
    font-size: 30px;
    color: #ffffff;
    position: relative;
  }
  span:after {
    content: '';
    width: 0;
    height: 0;
    border-width: 27px;
    border-style: solid;
    border-color: transparent transparent transparent #7c95d5;
    position: absolute;
    right: -53px;
    top: 0px;
  }
}
.banks {
  background-image: linear-gradient(88deg, #6680c3 0%, #2252cb 99%),
    linear-gradient(#7c95d5, #7c95d5);
}

// 多头借贷异常时间段
.duotoutable {
  width: 92%;
  height: 422px;
  border-radius: 15px;
  margin: 0 auto;
  background: #ff7643;
  margin-bottom: 40px;
  &-p {
    width: 100%;
    height: 80px;
    line-height: 80px;
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-weight: 600;
  }
}
table {
  width: 92%;
  height: 290px;
  border: 1px solid #debfb1;
  margin: 0 auto;
  background: #fff;
  border-collapse: collapse;
  text-align: center;
}
.overview {
  width: 92%;
  min-height: 666px;
  background: #fff;
  border-radius: 20px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 40px;
  .implement_info {
    display: flex;
    margin-top: 24px;
    padding-bottom: 4px;
    span {
      display: block;
      background: #f59631;
      color: #fff;
      padding: 5px 30px;
      border-radius: 25px;
      margin-right: 20px;
      font-size: 26px;
    }
  }
  .listp {
    width: 100%;
    min-height: 40px;
    margin-top: 20px;
    font-size: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .listp_l {
      color: #999;
      min-width: 200px;
    }
    .listp_r {
      color: #333;
    }
  }
}
.ovp {
  width: 100%;
  min-height: 70px;
  font-size: 28px;
  line-height: 70px;
  span {
    width: 180px;
    display: flex;
    border-bottom: 1px solid #333;
  }
}
.oneborders {
  width: 100%;
  height: 1px;
  background: #aaa;
  margin-top: 20px;
}
// -------------
.highRisk {
  padding: 20px;
  box-sizing: border-box;
  width: 92%;
  min-height: 600px;
  background: #fff;
  margin: 0 auto;
  border-radius: 15px;
  margin-bottom: 40px;
}
.ovps {
  width: 100%;
  min-height: 70px;
  font-size: 28px;
  line-height: 70px;
  span {
    width: 330px;
    display: flex;
    border-bottom: 1px solid #333;
  }
}
.hrbox {
  width: 100%;
  height: 66px;
  margin-top: 28px;
  .hrboxp1 {
    display: flex;
    justify-content: space-between;
    font-size: 26px;
  }
  .hrboxp2 {
    font-size: 22px;
    color: #85b3ff;
  }
}
.vagueTitle {
  width: 100%;
  height: 60px;
  // background: pink;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  .circularSmall {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #7c95d5;
    margin: 0 15px;
  }
  .circularIn {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #7c95d5;
    margin: 0 15px;
  }
  .circularLarge {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #7c95d5;
    margin: 0 15px;
  }
}
.vagueTitleBox1 {
  width: 92%;
  min-height: 500px;
  margin: 0 auto;
  background: #fff;
  border-radius: 25px;
  padding: 20px;
  box-sizing: border-box;
  line-height: 50px;
  // color: transparent;
  // text-shadow: #111 0 0 20px;
  margin-bottom: 20px;
  p {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
.footerbox {
  width: 92%;
  min-height: 400px;
  background: #fff;
  margin: 0 auto;
  border-radius: 25px;
  padding: 20px;
  box-sizing: border-box;
  .footerboxTitle {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 32px;
    // color: transparent;
    // text-shadow: #111 0 0 3px;
    // margin-bottom:30px;
    span {
      width: 250px;
      // background: red;
      border-bottom: 1px solid #333;
      display: flex;
    }
  }
  .fbcentent {
    font-size: 28px;
    margin-bottom: 20px;
    // color: transparent;
    // text-shadow: #111 0 0 16px;
  }
  .fbfooter {
    font-size: 28px;
    // color: transparent;
    // text-shadow: #111 0 0 1px;
    // opacity: 0.7;
  }
}
.wup {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.dwthree {
  position: fixed;
  right: 0;
  top: 40%;
}
.reportBox {
  width: 92%;
  min-height: 100px;
  background: #fff;
  margin: 0 auto;
  border-radius: 15px;
  text-align: center;
  p {
    padding-top: 20px;
    line-height: 50px;
    font-size: 26px;
    font-weight: 600;
  }
  .bannerContent {
    width: 100%;
    height: 460px;
    background: #4079fd;
    border-radius: 15px;
    margin: 0 auto;
    color: #fff;
    overflow: hidden;
    p:nth-child(1) {
      font-size: 36px;
      color: #fff;
      margin: 30px 0 10px 0;
    }
    p:nth-child(2) {
      font-size: 60px;
      color: #fff;
    }
    p:nth-child(3) {
      font-size: 28px;
      color: #afc4fd;
      margin-bottom: 50px;
    }
    p:nth-child(4) {
      width: 70%;
      height: 70px;
      background: #fff;
      font-size: 28px;
      color: #4d79e6;
      margin: 0 auto;
      border-radius: 50px;
      font-size: 30px;
    }
  }
  img {
    width: 95%;
    border-radius: 15px;
    padding: 15px 0;
    margin: 0 auto;
  }
}
.previewImg {
  width: 375px;
  height: 1000px;
  // background: red;
  border-radius: 20px;
  img {
    width: 100%;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.block {
  width: 66%;
  min-height: 300px;
  background-color: #fff;
  border-radius: 25px;
  padding: 5% 8%;
  img {
    width: 100%;
  }
  input {
    width: 100%;
    height: 75px;
    margin-bottom: 20px;
    background: #f0f0f0;
    color: #323233;
    border: none;
    border-radius: 12px;
    font-size: 28px;
    text-indent: 1em;
  }
  textarea {
    width: 100%;
    height: 200px;
    border-radius: 12px;
    font-size: 28px;
    text-indent: 1em;
    background: #f0f0f0;
    color: #323233;
    border: none;
  }
}
.close {
  margin-top: 20px;
}
</style>
